<template>
  <section>
    <ApolloQuery
      :query="require('@/graphql/PayrollDrivers.gql')"
      fetchPolicy="cache-and-network"
    >
      <!-- Loading -->

      <template slot-scope="{ result: { loading, error, data } }">
        <!-- Loading -->
        <div v-if="loading" class="loading apollo">Loading...</div>

        <!-- Error -->
        <div v-else-if="error" class="error apollo">An error occured</div>
        <div v-else-if="data">
          <v-card-title>
            Payroll
            <v-spacer></v-spacer>
            <v-checkbox
              class="filter-checkbox mr-4"
              value
              v-model="showNotPaid"
              v-if="isAdmin || isClientAdmin"
              label="Show Not Paid"
            ></v-checkbox>
            <v-checkbox
              class="filter-checkbox mr-4"
              value
              v-model="activated"
              v-if="isAdmin || isClientAdmin"
              label="Activated"
            ></v-checkbox>
            <!-- <v-select
              class="filter-input mr-2"
              single-line
              hide-details
              v-if="isAdmin"
              :items="
                !data.customers
                  ? [
                      {
                        text: 'All Clients',
                        value: null,
                      },
                    ]
                  : [
                      {
                        text: 'All Clients',
                        value: null,
                      },
                    ].concat(
                      data.customers.map((item) => {
                        return {
                          text: item.name,
                          value: parseInt(item.id),
                        };
                      })
                    )
              "
              v-model="customer"
              label="All Clients"
            ></v-select> -->
            <!-- <v-select
              class="filter-input mr-2"
              single-line
              v-if="isAdmin"
              hide-details
              :items="
                !roles
                  ? [
                      {
                        text: 'All Roles',
                        value: null,
                      },
                    ]
                  : [
                      {
                        text: 'All Roles',
                        value: null,
                      },
                    ].concat(roles)
              "
              v-model="role"
              label="All Roles"
            ></v-select> -->
            <v-text-field
              v-model="search"
              class="filter-input"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="data.users"
            class="elevation-1"
            :footer-props="{ 'items-per-page-options': [15, 30, 50, 100, -1] }"
            :items-per-page="30"
            :single-expand="singleExpand"
            :expanded.sync="expanded"
            show-expand
          >
            <template v-slot:top>
              <!-- {{editedItem}}
              {{defaultItem}}-->
              <v-toolbar flat color="white">
                <!-- <v-toolbar-title>My CRUD</v-toolbar-title> -->
                <!-- <v-divider class="mx-4" inset vertical></v-divider> -->
                <v-spacer></v-spacer>
                <!--  -->
              </v-toolbar>
            </template>

            <template v-slot:item.created_at="{ item }">
              <span
                v-luxon:date_med="{
                  value: item.created_at,
                }"
              />
            </template>
            <template v-slot:item.firstName="{ item }">
              {{ item.firstName }}
            </template>
            <template v-slot:item.lastName="{ item }">
              {{ item.lastName }}
            </template>
            <template v-slot:item.orders="{ item }">
              <!-- TODO: orders -> sub_orders -->
              {{ item.orders ? item.orders.length : 0 }}
            </template>
            <!-- <template v-slot:item.role="{ item }">
              {{ getRoleName(item.role) }}
            </template> -->
            <!-- <template v-slot:item.customer="{ item }">
              {{ item.customer ? item.customer.name : "-" }}
            </template> -->
            <!-- <template v-slot:item.showPrice="{ item }">
              {{ item.showPrice ? "yes" : "no" }}
            </template> -->
            <template v-slot:item.activated="{ item }">
              {{ item.activated ? "yes" : "no" }}
            </template>
            <!-- <template v-slot:item.actions="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    small
                    class="mr-2"
                    v-if="item.id != user.id"
                    @click="startChat(item, data)"
                    v-bind="attrs"
                    v-on="on"
                    >mdi-chat</v-icon
                  >
                </template>
                <span>Start Chat</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    small
                    class="mr-2"
                    @click="editItem(item, data)"
                    v-bind="attrs"
                    v-on="on"
                    >mdi-pencil</v-icon
                  >
                </template>
                <span>Edit User</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    small
                    @click="deleteItem(item, data)"
                    v-if="
                      (isAdmin || isClientAdmin) &&
                        item.id != user.id &&
                        item.activated != false
                    "
                    v-bind="attrs"
                    v-on="on"
                    >mdi-delete</v-icon
                  >
                </template>
                <span>Disable User</span>
              </v-tooltip>
            </template> -->
            <template v-slot:no-data>
              <v-btn color="primary" @click="initialize">Reset</v-btn>
            </template>

            <!-- expanded -->

            <template v-slot:expanded-item="{ item, headers }">
              <td :colspan="headers.length">
                <div class="d-flex justify-end mr-3 mt-2">
                  <div style="width: 200px;">
                    <v-dialog
                      ref="dialogStart"
                      v-model="invoiceModalVisibleStart"
                      width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="invoiceDateStart"
                          label="Date From"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          style="width: 150px"
                          class="ml-2 mr-2"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="invoiceDateStart"
                        :max="maxDateInvoice"
                        @click:date="invoiceModalVisibleStart = false"
                      >
                        <v-spacer></v-spacer>
                        <v-btn
                          text
                          color="primary"
                          @click="invoiceModalVisibleStart = false"
                        >
                          Cancel
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.dialogStart.save(invoiceDateStart)"
                        >
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-dialog>
                  </div>
                  <div style="width: 200px;">
                    <v-dialog
                      ref="dialogEnd"
                      v-model="invoiceModalVisibleEnd"
                      width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="invoiceDateEnd"
                          label="Date To"
                          prepend-icon="mdi-calendar"
                          readonly
                          style="width: 150px;"
                          class="ml-2 mr-2"
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="invoiceDateEnd"
                        :max="maxDateInvoice"
                        @click:date="invoiceModalVisibleEnd = false"
                      >
                        <v-spacer></v-spacer>
                        <v-btn
                          text
                          color="primary"
                          @click="invoiceModalVisibleEnd = false"
                        >
                          Cancel
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.dialogEnd.save(invoiceDateEnd)"
                        >
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-dialog>
                  </div>
                  <v-btn
                    color="primary"
                    dark
                    class="mt-2"
                    :disabled="!invoiceDateStart || !invoiceDateEnd"
                    @click="createInvoice(item)"
                    >Create Invoice</v-btn
                  >
                </div>

                <v-simple-table
                  class="order-expanded-table"
                  style="margin: 20px 0;"
                >
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th>Date Created</th>
                        <th>Date Range</th>
                        <th>Amount</th>
                        <th>Shared</th>
                        <th>Paid</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(invoiceItem, orderItemIdx) in item.invoices
                          .slice()
                          .reverse()
                          .filter((item) =>
                            showNotPaid ? item.paid == false : true
                          )"
                        :key="orderItemIdx"
                      >
                        <td>
                          <span
                            v-luxon:date_med="{
                              value: invoiceItem.dateCreated + 'T12:00:00',
                            }"
                          />
                        </td>
                        <td>
                          <span
                            v-luxon:date_med="{
                              value: invoiceItem.dateStart + 'T12:00:00',
                            }"
                          />
                          -
                          <span
                            v-luxon:date_med="{
                              value: invoiceItem.dateEnd + 'T12:00:00',
                            }"
                          />
                        </td>
                        <td>${{ invoiceItem.cost }}</td>
                        <td>
                          {{
                            (invoiceItem.shared
                            ? invoiceItem.shared
                            : invoiceItem.shared)
                              ? "yes"
                              : "no"
                          }}
                        </td>
                        <td
                          :style="{ color: invoiceItem.paid ? 'green' : 'red' }"
                        >
                          {{
                            (invoiceItem.paid
                            ? invoiceItem.paid
                            : invoiceItem.paid)
                              ? "yes"
                              : "no"
                          }}
                        </td>
                        <td>
                          <v-tooltip bottom v-if="!invoiceItem.shared">
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                @click="shareInvoice(item, invoiceItem)"
                                v-bind="attrs"
                                v-on="on"
                                >mdi-share</v-icon
                              >
                            </template>
                            <span>Share Invoice</span>
                          </v-tooltip>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                @click="downloadInvoice(item, invoiceItem)"
                                v-bind="attrs"
                                v-on="on"
                                >mdi-download</v-icon
                              >
                            </template>
                            <span>Download Invoice</span>
                          </v-tooltip>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                @click="sendInvoice(item, invoiceItem)"
                                v-bind="attrs"
                                v-on="on"
                                >mdi-email</v-icon
                              >
                            </template>
                            <span>Send Invoice</span>
                          </v-tooltip>

                          <v-tooltip bottom v-if="invoiceItem.shared">
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                v-if="!invoiceItem.paid"
                                @click="markPaidInvoice(item, invoiceItem)"
                                v-bind="attrs"
                                v-on="on"
                                >mdi-check</v-icon
                              >
                            </template>
                            <span>Mark Invoice as Paid</span>
                          </v-tooltip>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                v-if="!invoiceItem.paid"
                                @click="deleteInvoice(item, invoiceItem)"
                                v-bind="attrs"
                                v-on="on"
                                >mdi-close</v-icon
                              >
                            </template>
                            <span>Delete Invoice</span>
                          </v-tooltip>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </td>
            </template>
          </v-data-table>
        </div>

        <!-- No result -->
        <div v-else class="no-result apollo">No results</div>
      </template>
    </ApolloQuery>
  </section>
</template>
<script>
import { mapGetters } from "vuex";
import { DateTime } from "luxon";
import jsPDF from "jspdf";
import "jspdf-autotable";

export default {
  data: () => ({
    //
    invoiceDateStart: null,
    invoiceDateEnd: null,
    invoiceModalVisibleStart: false,
    invoiceModalVisibleEnd: false,
    //
    expanded: [],
    singleExpand: false,
    //
    dialog: false,
    //
    search: "",
    role: null,
    customer: null,
    activated: true,
    showNotPaid: false,
    //
    type: "hex",
    hex: "#2E7D32",
    // headers: ,
    roles: [
      {
        text: "Not Approved",
        value: 1,
      },
      {
        text: "Admin",
        value: 3,
      },
      {
        text: "Driver",
        value: 5,
      },
      {
        text: "ClientUser",
        value: 4,
      },
      {
        text: "ClientAdmin",
        value: 6,
      },
    ],
    editedIndex: -1,
    editedItem: {},
    defaultItem: {
      role: 1,
      showPrice: false,
      activated: true,
      confirmed: true,
    },
    //
    lookingForChat: false,
    //
    valid: true,
    //  hide-details="auto"
    // :rules="requiredRules"
    requiredRules: [(v) => !!v || "Field is required"],
    //
    requiredCustomerRules: [(v) => !!v || "Field is required"],
    requiredPhoneRules: [
      (v) => !!v || "Field is required",
      (v) => (v && v.length >= 14) || "Incorrect phone number",
    ],
  }),
  apollo: {
    // Chats: {
    //   prefetch: true,
    //   fetchPolicy: "cache-and-network",
    //   query: require("@/graphql/ChatsList.gql"),
    //   update: (data) => data,
    // },
  },
  computed: {
    maxDateInvoice() {
      var date = new Date();
      date.setDate(date.getDate() - 1);
      return date.toISOString().substring(0, 10);
    },
    // color: {
    //   get() {
    //     return this[this.type];
    //   },
    //   set(v) {
    //     this[this.type] = v;
    //   },
    // },
    // showColor() {
    //   if (typeof this.color === "string") return this.color;

    //   return JSON.stringify(
    //     Object.keys(this.color).reduce((color, key) => {
    //       color[key] = Number(this.color[key].toFixed(2));
    //       return color;
    //     }, {}),
    //     null,
    //     2
    //   );
    // },
    // formTitle() {
    //   return this.editedIndex === -1 ? "New User" : "Edit User";
    // },
    headers() {
      return [
        {
          text: "Created",
          align: "start",
          value: "created_at",
          filterable: false,
          sortable: true,
        },
        {
          text: "First Name",
          align: "start",
          value: "firstName",
          filterable: false,
          sortable: true,
        },
        {
          text: "Last Name",
          align: "start",
          value: "lastName",
          filterable: false,
          sortable: true,
        },
        {
          text: "Email",
          value: "email",
          filterable: false,
          sortable: true,
        },
        {
          text: "Phone",
          value: "phone",
          filterable: false,
        },
        {
          text: "Orders",
          value: "orders",
          roles: ["Admin"],
          filterable: false,
        },
        // { text: "Role", value: "role", filterable: false },
        // {
        //   text: "Client",
        //   value: "customer",
        //   roles: ["Admin"],
        //   filterable: false,
        // },
        // {
        //   text: "Show Price",
        //   value: "showPrice",
        //   roles: ["Admin"],
        //   filterable: false,
        //   sortable: true
        // },
        {
          text: "Activated",
          value: "activated",
          roles: ["Admin"],
          filterable: false,
        },
        // {
        //   text: "Actions",
        //   value: "actions",
        //   sortable: false,
        //   filter: this.searchFilter,
        // },
      ].filter(
        (item) => !item.roles || item.roles.includes(this.user.role.name)
      );
    },
    ...mapGetters(["user", "strapi", "isAdmin", "isClientAdmin"]),
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    "editedItem.role"(value) {
      if (value != 4 && value != 6) {
        this.editedItem.customer = -1;
      }
      // else {
      // this.editedItem.customer = null;
      // }
    },
  },

  created() {
    this.initialize();
  },
  mounted() {
    if (
      this.user.role.name == "ClientUser" ||
      this.user.role.name == "ClientAdmin"
    ) {
      this.defaultItem.customer = this.user.customer
        ? parseInt(this.user.customer.id)
        : undefined;
      this.defaultItem.role = 4;
      // Object.assign(this.editedItem, this.defaultItem);

      this.editedItem = this.lodash.cloneDeep(this.defaultItem);
    }
  },
  methods: {
    timeStringToDateTime(timeString) {
      var cFormat = "{y}-{m}-{d}";
      var vals = timeString.split("-");
      var formatSplit = cFormat.split("-");
      var m = formatSplit.indexOf("{m}");
      var d = formatSplit.indexOf("{d}");
      var y = formatSplit.indexOf("{y}");
      // console.log("month", vals[m]);
      // console.log("day", vals[d]);
      // console.log("year", vals[y]);

      return DateTime.local(
        parseInt(vals[y]),
        parseInt(vals[m]),
        parseInt(vals[d]),
        0,
        0,
        0
      );
    },
    async markPaidInvoice(item, invoiceItem) {
      var result = confirm("Are you sure you want to mark invoice as paid?");
      if (result) {
        var updatedItem = await this.strapi.updateEntry(
          "invoices",
          parseInt(invoiceItem.id),
          {
            paid: true,
          }
        );
        Object.assign(invoiceItem, updatedItem);
      }
    },
    async sendInvoice(item, invoiceItem) {
      this.strapi.request("POST", "/sendInvoice", {
        data: {
          type: "driver",
          driver: item.id,
          invoiceItem: invoiceItem.id,
        },
      });
      alert("Invoice sent.");
    },
    async shareInvoice(item, invoiceItem) {
      // var result = confirm("Are you sure you want to mark invoice as paid?");
      // if (result) {
      var updatedItem = await this.strapi.updateEntry(
        "invoices",
        parseInt(invoiceItem.id),
        {
          shared: true,
        }
      );
      Object.assign(invoiceItem, updatedItem);
      // }
    },
    async downloadInvoice(item, invoiceItem) {
      var invoiceOwner = invoiceItem.customer
        ? invoiceItem.customer.name
        : `${invoiceItem.user.firstName} ${invoiceItem.user.lastName}`;

      let pdfName = `${invoiceOwner}. Invoice #${invoiceItem.id}.`;
      var imgData = require("@/assets/base64/logo.js");
      // console.log(imgData);
      var doc = new jsPDF();
      doc.setFontSize(12);
      doc.setTextColor(160, 160, 160);
      doc.text(`Created: ${invoiceItem.dateCreated}`, 10, 10);
      doc.setTextColor(0, 0, 0);
      doc.setFontSize(14);
      doc.text(`${invoiceOwner}.`, 10, 20);
      doc.text(`Invoice #${invoiceItem.id}`, 10, 28);
      doc.setFontSize(12);
      doc.setTextColor(160, 160, 160);
      doc.text(`(${invoiceItem.dateStart} - ${invoiceItem.dateEnd})`, 10, 35);
      doc.setTextColor(0, 0, 0);
      doc.addImage(imgData.default, "JPEG", 160, 5, 40, 19.6); //0.4

      doc.autoTable({
        // styles: { fillColor: [255, 255, 255] },
        // tableLineWidth: 1,
        // columnStyles: { 1: { halign: "center" }, 2: { halign: "center" }  }, //, fillColor: [0, 255, 0] } }, // Cells in first column centered and green
        margin: { top: 40 },
        head: [
          ["Date", "Appointment time", "Destination", "Passenger", "Cost"],
        ],
        body: invoiceItem.records.map((item) => {
          return [
            item.date,
            item.time,
            item.destination,
            item.patientName,
            `$${item.price}`,
          ];
        }),
      });

      doc.setFontSize(11);
      doc.text(
        `Total: $${invoiceItem.cost}`,
        168,
        60 + 10 * invoiceItem.records.length
      );

      doc.setTextColor(160, 160, 160);
      doc.setFontSize(11);
      doc.text(
        invoiceItem.customer
          ? `THANK YOU FOR ALLOWING US TO PARTICIPATE IN YOUR PATIENTS CARE`
          : "THANK YOU",
        30,
        50 + 10 * (invoiceItem.records.length + 5)
      );
      doc.setTextColor(0, 0, 0);

      //pages
      const pages = doc.internal.getNumberOfPages();
      const pageWidth = doc.internal.pageSize.width; //Optional
      const pageHeight = doc.internal.pageSize.height; //Optional
      doc.setFontSize(10); //Optional
      doc.setTextColor(0, 0, 0);

      for (let j = 1; j < pages + 1; j++) {
        let horizontalPos = pageWidth / 2; //Can be fixed number
        let verticalPos = pageHeight - 5; //Can be fixed number
        doc.setPage(j);
        doc.text(`${j} of ${pages}`, horizontalPos, verticalPos, {
          align: "center", //Optional text styling});
        });
      }

      doc.save(pdfName + ".pdf");
    },
    async deleteInvoice(item, invoiceItem) {
      const index = item.invoices.findIndex((i) => invoiceItem.id == i.id);
      var result = confirm("Are you sure you want to delete this item?");
      if (result) {
        await this.strapi.deleteEntry("invoices", invoiceItem.id);
        item.invoices.splice(index, 1);
      }
    },

    //
    async createInvoice(item) {
      if (!this.invoiceDateStart || !this.invoiceDateEnd) {
        return;
      }

      var startDate = this.timeStringToDateTime(this.invoiceDateStart);
      var endDate = this.timeStringToDateTime(this.invoiceDateEnd);

      var costs = 0;
      var records = [];

      var subOrders = await this.strapi.getEntries("sub-orders", {
        driver: item.id,
      });

      for (let i = 0; i < subOrders.length; i++) {
        if (subOrders[i].status == "Completed") {
          var subOrderDate = this.timeStringToDateTime(subOrders[i].date);

          if (
            subOrderDate.diff(startDate, "days").toObject().days >= 0 &&
            subOrderDate.diff(endDate, "days").toObject().days <= 0
          ) {
            costs += subOrders[i].priceDriver; //!!!!
            // console.log(subOrders[i]);
            records.push({
              date: subOrders[i].date,
              time: subOrders[i].time,
              price: subOrders[i].priceDriver, //!!!!
              patientName: subOrders[i].patientName,
              destination: subOrders[i].destination,
            });
          }
        }
      }

      var today = DateTime.local();
      today = DateTime.local(today.year, today.month, today.day);
      // console.log(today.toISODate());

      var invoice = await this.strapi.createEntry("invoices", {
        dateStart: this.invoiceDateStart,
        dateEnd: this.invoiceDateEnd,
        dateCreated: today.toISODate(),
        paid: false,
        cost: costs,
        user: item.id, //!!!!
        customer: undefined,
        records: records,
      });

      if (!item.invoices) item.invoices = [];
      item.invoices.push(invoice);
    },
    formatPhoneNumber(str) {
      //Filter only numbers from the input
      let cleaned = ("" + str).replace(/\D/g, "");

      //Check if the input is of correct length
      let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

      if (match) {
        return "(" + match[1] + ") " + match[2] + "-" + match[3];
      }

      return null;
    },
    updateValue() {
      var valClean = this.editedItem.phone
        ? this.editedItem.phone.replace(/\D/g, "")
        : this.editedItem.phone;
      if (valClean && valClean.length > 10) {
        this.editedItem.phone = this.formatPhoneNumber(
          valClean.substring(0, 10)
        );
        this.$forceUpdate();
      }
    },
    // getRoleName(role) {
    //   if (!role) return "-";
    //   for (let i = 0; i < this.roles.length; i++) {
    //     if (this.roles[i].value == role.id) return this.roles[i].text;
    //   }
    // },
    searchFilter(_value, _search, item) {
      if (item.activated != this.activated) return false;

      if (this.role) {
        if (!item.role || parseInt(item.role.id) != this.role) return false;
      }

      if (this.customer) {
        if (!item.customer || parseInt(item.customer.id) != this.customer)
          return false;
      }

      var searchWord = this.search.toLowerCase();
      if (
        (item.firstName && item.firstName.toLowerCase().includes(searchWord)) ||
        (item.lastName && item.lastName.toLowerCase().includes(searchWord)) ||
        (item.phone && item.phone.toLowerCase().includes(searchWord)) ||
        (item.email && item.email.toLowerCase().includes(searchWord))
      )
        return true;
      return false;
    },
    initialize() {
      // Object.assign(this.editedItem, this.defaultItem);

      this.editedItem = this.lodash.cloneDeep(this.defaultItem);
    },
    // async startChat(item, data) {
    //   if (this.lookingForChat) return;
    //   this.lookingForChat = true;
    //   var chatFound = undefined;
    //   var chatsCached = this.Chats && this.Chats.chats ? this.Chats.chats : [];
    //   for (let i = 0; i < chatsCached.length; i++) {
    //     if (!chatsCached[i].users) continue;
    //     if (
    //       chatsCached[i].users
    //         .map((item) => item.email)
    //         .join(", ")
    //         .toLowerCase()
    //         .includes(item.email.toLowerCase())
    //     ) {
    //       chatFound = chatsCached[i];
    //       break;
    //     }
    //   }

    //   // console.log(chatFound);

    //   try {
    //     if (!chatFound) {
    //       chatFound = await this.strapi.createEntry("chats", {
    //         users: [this.user.id, parseInt(item.id)],
    //       });
    //     }
    //     this.lookingForChat = false;
    //   } catch (err) {
    //     this.lookingForChat = false;
    //     return;
    //   }

    //   this.$router.push({ name: "ChatPage", query: { id: chatFound.id } });
    // },
    // editItem(item, data) {
    //   this.editedIndex = data.users.findIndex((i) => i.id == item.id);
    //   // Object.assign(this.editedItem, item);

    //   this.editedItem = this.lodash.cloneDeep(data.users[this.editedIndex]);
    //   this.editedItem.role = parseInt(item.role.id);

    //   this.editedItem.customer = data.users[this.editedIndex].customer;
    //   // console.log(this.editedItem.customer);
    //   this.editedItem.customer = this.editedItem.customer
    //     ? parseInt(this.editedItem.customer.id)
    //     : undefined;
    //   // console.log(this.editedItem.customer);
    //   if (this.editedItem.color) this.hex = this.editedItem.color;

    //   this.dialog = true;
    // },

    // async deleteItem(item, data) {
    //   const index = data.users.findIndex((i) => i.id == item.id);
    //   var result = confirm("Are you sure you want to delete this item?");
    //   if (result) {
    //     // await this.strapi.deleteEntry("users", item.id);
    //     // data.users.splice(index, 1);
    //     var updatedItem = await this.strapi.updateEntry(
    //       "users",
    //       parseInt(item.id),
    //       {
    //         activated: false,
    //       }
    //     );
    //     Object.assign(data.users[index], updatedItem);
    //   }
    // },

    // close() {
    //   this.dialog = false;
    //   this.$nextTick(() => {
    //     // Object.assign(this.editedItem, this.defaultItem);

    //     this.editedItem = this.lodash.cloneDeep(this.defaultItem);
    //     this.editedIndex = -1;
    //   });
    // },

    //   async save(data) {
    //     this.$refs.form.validate();
    //     if (this.$refs.form.validate(true)) {
    //       this.editedItem.color = this.showColor;
    //       this.editedItem.email = this.editedItem.email.toLowerCase();
    //       this.editedItem.username = this.editedItem.email.toLowerCase();

    //       if (
    //         this.user.role.name == "ClientUser" ||
    //         this.user.role.name == "ClientAdmin"
    //       ) {
    //         this.editedItem.customer = this.user.customer
    //           ? parseInt(this.user.customer.id)
    //           : undefined;
    //       }

    //       if (this.editedIndex > -1) {
    //         var updatedItem = await this.strapi.updateEntry(
    //           "users",
    //           parseInt(this.editedItem.id),
    //           this.editedItem
    //         );
    //         Object.assign(data.users[this.editedIndex], updatedItem);
    //       } else {
    //         var newItem = await this.strapi.createEntry("users", this.editedItem);
    //         data.users.push(newItem);
    //       }
    //       this.close();
    //     }
    //   },
  },
};
</script>
<style lang="scss" scoped></style>
